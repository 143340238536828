import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import FlowType from "../../enums/flowType.enum"
import { PanelModal } from "../panelModal/panelModal.component"
import { advancedPanel } from "../../data/advancedPanel.data"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import ConfirmAppointment from "./confirmAppointment.component"
import { addProduct } from "../../services/product.service"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import { useNavigate } from "react-router-dom"
import { listResults } from "../../services/result.service"
import { listTests } from "../../services/test.service"
import TestCode from "../../enums/testCode.enum"
import { buildUser } from '../../services/user.service'
import { getAvailability } from "../../services/schedule.service"

export const Step = {
  ACCOUNT: 'account',
  LOCATION: 'location',
  PSC: 'psc',
  PAY: 'pay',
  ADDON: 'add-ons',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  SCHEDULE: 'schedule',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation',
  INTAKE: 'intake',
}

export const LongevityTestPscFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipType, setMembershipType] = useState()
  const [phlebType, setPhlebType] = useState()
  const [hasPass, setHasPass] = useState()
  const [hasApoe, setHasApoe] = useState()
  const hasCompletedFlowRef = useRef(false)

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  console.log("flow-psc", flow)

  useEffect(() => {
    // don't show add on if has already run apoe
    const fetchHasApoe = async () => {
      const [test] = await listTests({
        select: '_id',
        filter: { code: TestCode.APO_E }
      });
  
      const results = await listResults({
        select: '_id values',
        filter: { patient: currentUser }
      });
  
      const hasApoE = results.some(({ values }) =>
        values.some(value => value.value && value.test.toString() === test._id.toString())
      );
  
      setHasApoe(hasApoE);
    };
  
    fetchHasApoe();
  }, [currentUser]);

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, flow, currentUser, phlebType, hasPass, hasApoe])

  useEffect(() => {
    fetchPhlebType()
  }, [currentUser, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchPhlebType = async () => {
    if (!currentUser?.location || !flow) return
    const response = await getAvailability({
      startDate: new Date(),
      location: currentUser.location,
      flowId: flow._id
    })
    setPhlebType(response.phlebType)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LONGEVITY_PANEL_PSC,
            ProductTypeCode.OMEGACHECK,
            ProductTypeCode.APOE_ADDON,
            ProductTypeCode.HEAVY_METALS,
            ProductTypeCode.CORTISOL
          ]
        }
      }
    }))
  }


  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_TEST_PSC}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PSC,
        title: `Where are you located?`,
        description: `We'll find the closest patient service center for your blood draw.`,
        field: {
          name: 'location'
        },
      },
      [Step.PSC]: {
        type: StepType.FACILITY,
        nextStep: Step.PAY,
        buildFlow: true,
        multiple: false,
        productTypeCode: ProductTypeCode.LONGEVITY_PANEL,
        title: `Select your preferred patient service center.`,
        description: `This is where you'll get your blood draw done.`,
        field: {
          name: 'facility',
          required: true,
        },
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.ADDON,
        addProduct: true,
        productType: ProductTypeCode.LONGEVITY_PANEL_PSC,
        addLongevityMembership: true,
        title: <>{ flow?.addLongevityMembership ? <>Pay <span className="true-price">${getProductType(ProductTypeCode.LONGEVITY_PANEL_PSC)?.cost}</span> for the Longevity Blood Panel and <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership.</>
            : <>Pay <span className="true-price">${getProductType(ProductTypeCode.LONGEVITY_PANEL_PSC)?.cost}</span> for the Longevity Blood Panel.</>}</>,
        description: <>Includes testing for over 60 biomarkers, a detailed report explaining your health risks, and a personalized action plan to optimize your long-term health.</>,
      },
      [Step.ADDON]: {
        type: StepType.PRODUCT_SELECT,
        title: `Would you like to add on any tests to the Longevity Panel?`,
        description: `Included in the same blood draw appointment. If you want anything else not on this list, please email concierge@instalab.com after you finish booking.`,
        buildFlow: true,
        buildUser: true,
        multiple: true,
        hasSkip: true,
        buttonText: 'Add Tests',
        nextStep: Step.GENDER,
        field: {
          name: 'longevityTestAddOns',
          required: true,
          options: [
            {
              title: `${getProductType(ProductTypeCode.OMEGACHECK)?.title}`,
              value: ProductTypeCode.OMEGACHECK,
              description: getProductType(ProductTypeCode.OMEGACHECK)?.tagline,
              tag: null,
              price: getProductType(ProductTypeCode.OMEGACHECK)?.cost
            }, 
            {
              title: `${getProductType(ProductTypeCode.CORTISOL)?.title}`,
              value: ProductTypeCode.CORTISOL,
              description: getProductType(ProductTypeCode.CORTISOL)?.tagline,
              tag: null,
              price: getProductType(ProductTypeCode.CORTISOL)?.cost
            },             
            // {
            //   title: `${getProductType(ProductTypeCode.HEAVY_METALS)?.title}`,
            //   value: ProductTypeCode.HEAVY_METALS,
            //   description: getProductType(ProductTypeCode.HEAVY_METALS)?.tagline,
            //   tag: null,
            //   price: getProductType(ProductTypeCode.HEAVY_METALS)?.cost
            // }, 
            hasApoe ? null : {
              title: `${getProductType(ProductTypeCode.APOE_ADDON)?.title}`,
              value: ProductTypeCode.APOE_ADDON,
              description: getProductType(ProductTypeCode.APOE_ADDON)?.tagline,
              tag: null,
              price: getProductType(ProductTypeCode.APOE_ADDON)?.cost
            }, 
          ].filter(Boolean) 
        },
        onSuccess: async (patient) => {

          try {

            await buildUser({
              flow: flow._id,
              fields: {
                longevityTestAddOns: []  // Set empty array
              }
            });

            if (patient?.longevityTestAddOns.length>0) {
              for (const product of patient?.longevityTestAddOns) {
                await addProduct({
                    fields: {
                      type: product,
                      flowId: flow?._id
                    }
                })
  
              }
   
            }
            
          } 
          catch (error) {
            console.log(error)
          }

          return true
        },
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.OMEGACHECK, ProductTypeCode.HEAVY_METALS, ProductTypeCode.APOE_ADD_ON].includes(product.productType.code)).length > 0
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SCHEDULE,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Schedule your blood draw appointment.`,
        description: `Please note the appointment takes 10 minutes, and you must fast 8 hours before your appointment (water and black coffee are fine).`, 

      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: flow?.appointment ? `Your blood draw appointment is confirmed!` : `Final Steps!`,
        description: flow?.appointment ? <>You’ll receive a lab order via email shortly. Please print it and bring it with you to your appointment.<br/><br/>Next, we need a litle bit more information to provide accurate analysis and personalized recommendations.</> : <>Please book your blood draw appointment and fill out your intake.</>,
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }


  return skeleton && <>
    <PanelModal
      panel={advancedPanel}
      title="Longevity Blood Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={1}
    />
  </>
}