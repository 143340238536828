import { Typography } from "antd";
import "./liverDiseaseAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import LowercaseTest from "../../../enums/lowercaseTest.enum";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from "../../../helpers/report.helper";
import { TestCode, Condition, QuestionTemplateCode, Recommendation, RiskLevel } from "../../../enums/index.enum";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import PatientHelper from "../../../helpers/patient.helper";
import { TestModal } from "../../testModal/testModal.component";

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const LiverDiseaseAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [liverTests, setLiverTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [hasRecentViralInfection, setHasRecentViralInfection] = useState([]);
  const [openModal, setOpenModal] = useState()
  const [testId, setTestId] = useState()

  // baseline or followup?
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.LIVER_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.LIVER_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.LIVER_ADVICE));
    setHasRecentViralInfection(ReportHelper.getAnswer(report, QuestionTemplateCode.RECENT_VIRAL_INFECTION))
  }, [report, tests]);

  useEffect(() => {
    setLiverTests(getLiverTests());

  }, [report, tests, isFixed, isBetter]);


  const getLiverTests = () => {
    if (!report || !tests) return null;
    if (isFixed) return ReportHelper.getSectionFixedTests(report, ReportSection.LIVER_ADVICE, tests);
    if (isBetter) return ReportHelper.getSectionImprovedTests(report, ReportSection.LIVER_ADVICE, tests)
    return ReportHelper.getSectionTests(report, ReportSection.LIVER_ADVICE, tests);
  };


  useEffect(() => {
    const hasIsolatedHyperbilirubinemia = ReportHelper.hasCondition(report, Condition.ISOLATED_HYPERBILIRUBINEMIA)

    setTitle(ReportHelper.isAthletePanel(report) && !hasIsolatedHyperbilirubinemia ? getAthleteTitle() : getTitle());
    setDescription(getDescription());
  }, [report, results, liverTests, isFixed, isBetter, isWorse, hasRecentViralInfection]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report  || !tests || !liverTests) return null

    const hasThalassemia = ReportHelper.hasCondition(report, Condition.THALASSEMIA)
    const hasIsolatedHyperbilirubinemia = ReportHelper.hasCondition(report, Condition.ISOLATED_HYPERBILIRUBINEMIA)
    const hasPossibelNASH = ReportHelper.hasCondition(report, Condition.POSSIBLE_NASH)
    const hasPossibleTransientLiverIssue = ReportHelper.hasCondition(report, Condition.POSSIBLE_TRANSIENT_LIVER_ISSUE)

    if (hasThalassemia && hasIsolatedHyperbilirubinemia) {
      return <>
      {liverTests.length === 1 
        ? <>Your <span>{LowercaseTest[liverTests[0].code]}</span> level is</>
        : <>These{" "}<span>{converter.toWords(liverTests.length)} liver biomarkers</span> are</>
      }{" "} <span>elevated</span> likely due to your confirmed  <span>thalassemia</span>.     
    </>         
    }

    if (isWorse) {
      if (hasPossibleTransientLiverIssue)
        return <>These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span> suggest a decline in <span>liver health</span>, but possibly temporary.</>

      return <>Your overall <span>liver health</span> has <span>declined</span> since your last blood draw.</>
    }

    else if (isBetter || isFixed) {
      return <>Your <span>liver health</span> has <span>improved</span> since your last blood draw.</>
    }


    else if (report?.result?.conditions.includes(Condition.ISOLATED_INDIRECT_HYPERBILIRUBINEMIA)) {
      return <>These <span>two biomarkers</span> suggest possible <span>hyperbilirubinemia</span>.</>
    }

    else if (report?.result?.conditions.includes(Condition.ISOLATED_DIRECT_HYPERBILIRUBINEMIA)) {
      return <>These <span>two biomarkers</span> suggest possible <span>hyperbilirubinemia</span>.</>
    }

    else if (hasRecentViralInfection) {
      return <>         
      {liverTests.length === 1 
        ? <>Your <span>{LowercaseTest[liverTests[0].code]}</span> level typically indicates</>
        : <>These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}typically indicate</>
      }<> <span>liver inflammation</span>, but is more likely due to your recent <span>viral infection</span>.</>
    </>      
    }

    else if (hasIsolatedHyperbilirubinemia) {
      return  <>These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}suggest you have <span>isolated hyperbilirubinemia</span>.</>
    }

    return <>         
      {liverTests.length === 1 
        ? <>Your <span>{LowercaseTest[liverTests[0].code]}</span> level suggests</>
        : <>These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}suggest</>
      }{" "}you have possible{" "}
      <span>{(report.conditions?.includes(Condition.LIVER_DISEASE_SEVERE) || hasPossibelNASH) ? <>liver damage</>: <>liver inflammation</>}</span>.
    </>
  }

  const getAthleteTitle = () => {
    if (!report || !tests || !liverTests) return null

    return <>         
      {liverTests.length === 1 
        ? <>Your <span>elevated {LowercaseTest[liverTests[0].code]}</span> level suggests</>
        : <>These{" "}<span>{converter.toWords(liverTests.length)} liver biomarkers</span>{" "}suggest</>
      }{" "}recent intense exercise.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !tests || !liverTests) return null
    
    const hasThalassemia = ReportHelper.hasCondition(report, Condition.THALASSEMIA)
    const hasIsolatedHyperbilirubinemia = ReportHelper.hasCondition(report, Condition.ISOLATED_HYPERBILIRUBINEMIA)
    const recommendations = ReportHelper.getRecommendations(report, ReportSection.LIVER_ADVICE)
    const hasRepeat = recommendations?.includes(Recommendation.REPEAT_LIVER_TESTS)
    const hasPossibelNASH = ReportHelper.hasCondition(report, Condition.POSSIBLE_NASH)
    const hasPossibleTransientLiverIssue = ReportHelper.hasCondition(report, Condition.POSSIBLE_TRANSIENT_LIVER_ISSUE)
    const hasMetabolicIssue = ReportHelper.hasSection(report, ReportSection.METABOLIC_ADVICE)

    if (hasRecentViralInfection) {
      return <>These liver biomarkers can rapidly increase to due a number of reasons including various recent intense exercise, excessive alcohol use or various viruses that can affect the liver.
      <br/><br/>You should confirm whether this is temporary or persistent elevation through a follow-up
      test in a few weeks before drawing conclusions. And/or consult with your primary care physician to help pinpoint the source of this elevation and to determine your best management strategy.</>
    }

    if (liverTests.length ===1 && [TestCode.ALT].includes(liverTests[0].code) && !hasMetabolicIssue) {
      return <>Elevated ALT levels in isolation as the only abnormal liver marker are typically associated with metabolic-related issues. However, given your excellent metabolic health, optimal HOMA-IR and triglyceride levels, and active lifestyle, these elevations in ALT are generally not a cause for concern.</>
    }

    if (((liverTests.length===1) && (liverTests[0].code===TestCode.AST))) {
      return <>Liver enzymes can temporarily increase to due a number of reasons including recent intense exercise, excessive alcohol use or various viral infections. It's worth monitoring this to make sure they return back to healthy levels. 
      </>
    }

    if (hasPossibleTransientLiverIssue) {
      return <>These liver biomarkers can temporarily increase to due a number of reasons including recent intense exercise, excessive alcohol use or various illnesses and infections. While this isn't concerning for now, it's worth monitoring this to make sure they return back to healthy levels. 
      </>
    }
    
    if (isBetter) {
      return <>
        These {converter.toWords(liverTests.length)} biomarkers are moving your overall liver health towards a healthy range. Let's continue to monitor and see where it stabilizes in your next blood draw.
      </>
    }


    if (isFixed) {
      return <>
        Since these {converter.toWords(liverTests.length)} biomarkers have returned back to healthy range, no need to worry about previous readings. Continue to monitor to make sure they stay optimal.

  
      </>
    }

    if (hasPossibelNASH) {
      
      return <>Your elevated ALT levels, in conjunction with metabolic conditions, suggest NAFLD (non-alcoholic fatty liver disease), which is often mangeable with the before-mentioned gameplan. However, the additional presence of elevated ALP is concerning. It could just be NAFLD but it could also indicate a progression towards more serious issue.
      <br/><br/>It's highly recommended to consult a hepatologist to accurately diagnose the underlying problem and develop an effective treatment plan.

      </>
    }

    if (hasIsolatedHyperbilirubinemia && hasThalassemia) {
      return <>In thalassemia, rapid breakdown of red blood cells increases bilirubin, which in severe cases can cause jaundice (yellow skin and eyes).  While this is a condition to monitor closesly, it's not an immediate concern. Consult your physician should your bilirubin levels significantly rise in the future.

      </>
    }

    if (isWorse) {

      return <>         
        {liverTests.length === 1 
          ? <>Your <span>{LowercaseTest[liverTests[0].code]}</span> level suggests</>
          : <>These{" "}<span>{converter.toWords(liverTests.length)} biomarkers</span>{" "}suggest</>
        }{" "}you have {" "}
        <span>{hasIsolatedHyperbilirubinemia ? <>hyperbilirubinemia</>: <>liver inflammation</>}</span>. 
        
        {hasIsolatedHyperbilirubinemia ? <> Bilirubin is a yellow pigment produced from the breakdown of your red blood cells. Usually it's processed by the liver and then excreted into the bile. But in your case, there may be an impairment in this process.</>
        :<> It's recommended you consult your primary care provider to pinpoint the source of this elevation and to determine your best management strategy.</>}


        </>
    }

    if (hasIsolatedHyperbilirubinemia) {
      const highRisk = (ReportHelper.getTestValue(TestCode.TOTAL_BILIRUBIN, report, results, tests) > 4)
      return <>
      Bilirubin is a yellow pigment produced from the breakdown of your red blood cells. Usually it's processed by the liver and then excreted into the bile. But in your case, there may be {!highRisk ? " a mild" : " a"} impairment in this process. 

      { !highRisk ?
      <><br/><br/>Mild isolated hyperbilirubinemia, especially without other other symptoms (like abdominal pain, fatigue, or changes in stool/urine color), is not a cause for immediate concern and can sometimes be temporary. That said, it's worth closely monitoring cloesly over time. </>

      : <><br/><br/>Jaundice, yellowing of the skin and the whites of the eyes, can be a symptom as the excess bilirubin deposits in the tissues. 
      {!hasRepeat && <> It would be a good idea to consult your primary care provider to check if there’s any need for concern.</>} </>}


      </>
    }

    // ATHLETE?
    const CK = ReportHelper.getTestValue(TestCode.CREATINE_KINASE, report, results, tests) 
    const hasHighCK = CK && CK > 300
  
    if (PatientHelper.isAthlete(user) && hasHighCK) {

      const handleViewTest = (testCode) => {
        setTestId(tests.find((t) => t.code === testCode)._id);
        setOpenModal(true);
      };
  
      return <>Elevated {ReportHelper.getTestNames(liverTests)} levels typically indicate liver issues. But in athletes, these elevated levels combined with <a href="#" onClick={()=> handleViewTest(TestCode.CREATINE_KINASE, tests, setTestId, setOpenModal)}>elevated creatinine kinase levels</a> are usually attributed to recent intense physical exercise. This exercise-induced elevation is generally temporary and unrelated to liver dysfunction. To be sure, you can get a comprehensive liver panel done (at a time when you're able to take a break from exercise to avoid false positives).
      </>
    }

    if ((liverTests.length===2) && liverTests?.some(test => test.code === TestCode.AST) && liverTests?.some(test => test.code === TestCode.ALT)) {
      return <>Both AST and ALT are enzymes that can be released into the bloodstream when liver cells are damaged.
      {!hasRepeat ? <> Sometimes these elevations are transient. So while mild right now, it's worth closely monitoring over time to make sure these biomarkers don't worsen.</> : <> But sometimes these elevations are transient due to factors like recent strenuous physical activity, alcohol consumption and viral infections. </>}
      
      </>
    }


    // if all is moderate, monitor 
    const highRiskResults = liverTests.filter(test=> ReportHelper.getTestRisk(test.code, report, results, tests) === RiskLevel.HIGH)

    if (highRiskResults.length <= 0) 
      return <> Elevated {ReportHelper.getTestNames(liverTests)} levels can often indicate liver-related issues, such as liver damage, liver disease, or bile duct obstruction. That said, sometimes these elevations are transient. So while mild right now, it's 
    </>

    return <>
      Elevated {ReportHelper.getTestNames(liverTests)} levels can often indicate liver-related issues, such as liver damage, liver disease, or bile duct obstruction. However, temporary increases in these levels can also occur due to factors such as recent intense exercise, heavy alcohol consumption, or viral infections.


      </>
  }


  return liverTests && (
    <div className="liver-disease-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>
      <TestModal
        open={openModal}
        setOpen={setOpenModal}
        testId={testId}
        setTestId={setTestId}
        report={report}
        tests={tests}
        results={results}
        reports={reports}
      />
      <TestView 
        report={report} 
        tests={liverTests} 
        results={results}
        reports={reports}
        user={user}
      />
      <AdviceSection 
        report={report}  
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  )
};
