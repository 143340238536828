import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { ToxicMetalsTestIntro } from "./toxicMetalsTestIntro.component"
import { ToxicMetalsTestConfirm } from "./toxicMetalsTestConfirm.component"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  DELIVERY: 'delivery',
  // MEMBERSHIP: 'membership',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const ToxicMetalsTestFlow = () => {
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()
  const hasCompletedFlowRef = useRef(false)
  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, instalabMembership, flow, hasPass])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.TOXIC_METALS_URINE_TEST,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => 
        <ToxicMetalsTestIntro  
          footer={footer} 
          productType={getProductType(ProductTypeCode.TOXIC_METALS_URINE_TEST)}
        />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.TOXIC_METALS_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is your biological sex?`,
        description: `We need this for your lab order for the test.`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY,
        title: `What's your delivery address?`,
        description: `We’ll send collection kit to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      // [Step.MEMBERSHIP]: {
      //   type: StepType.PAY,
      //   nextStep: Step.PAY,
      //   addSubscription: true,
      //   subscriptionType: MembershipTypeCode.LONGEVITY,
      //   title: <>Activate Instalab membership for <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span>. Cancel anytime.</>,
      //   description: "This test is exclusive to members, who also enjoy special savings, a care concierge, access to longevity physicians, and more. Easily cancel anytime.",
      //   skipIf: () => {
      //     return instalabMembership
      //   }
      // },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: true,
        productType: ProductTypeCode.TOXIC_METALS_URINE_TEST,
        addLongevityMembership: true,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.TOXIC_METALS_URINE_TEST)?.cost}</span> for the Toxic Metals Test.</>,
        description: `This includes a collection kit, results and report on your exposure to 20 toxic metals.`,
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Confirmed!`,
        description: `Your test kit will be shipped within 1 business day. Tracking info will be available shortly in your account.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ToxicMetalsTestConfirm/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}