import Role from '../enums/role.enum.js'
import ProductTypeCode from "../enums/productTypeCode.enum.js"
import PlanCode from "../enums/planCode.enum.js"

const UserHelper = {
  isAdmin: (user) => {
    return user?.role === Role.ADMIN
  },
  isPatient: (user) => {
    return user?.role === Role.PATIENT
  },
  isPhlebotomist: (user) => {
    return user?.role === Role.PHLEBOTOMIST
  },
  isProvider: (user) => {
    return user?.role === Role.PROVIDER
  },
  isStaff: (user) => {
    return user?.role === Role.STAFF
  },
  hasCredit: (user, productType) => {
    if (!user?.credits) return false;
  
    const creditDict = {
      [ProductTypeCode.LONGEVITY_PANEL]: [PlanCode.ADVANCED, ProductTypeCode.LONGEVITY_PANEL],
      [ProductTypeCode.LONGEVITY_PANEL_PSC]: [ProductTypeCode.LONGEVITY_PANEL_PSC],
      [ProductTypeCode.ATHLETE_PANEL]: [PlanCode.ATHLETE, ProductTypeCode.ATHLETE_PANEL],
      [ProductTypeCode.CONSULT_LONGEVITY]: [ProductTypeCode.CONSULT_LONGEVITY],
      [ProductTypeCode.CONSULT_HEART_HEALTH]: [ProductTypeCode.CONSULT_HEART_HEALTH],
      [ProductTypeCode.CONSULT_METABOLIC_HEALTH]: [ProductTypeCode.CONSULT_METABOLIC_HEALTH],
      [ProductTypeCode.WATCHPAT_ONE]: [ProductTypeCode.WATCHPAT_ONE],
      [ProductTypeCode.OMEGA_3_ADDON]: [ProductTypeCode.OMEGA_3_ADDON],
      [ProductTypeCode.APOE_ADDON]: [ProductTypeCode.APOE_ADDON],
      [ProductTypeCode.OMEGACHECK]: [ProductTypeCode.OMEGACHECK],
      [ProductTypeCode.HEAVY_METALS]: [ProductTypeCode.HEAVY_METALS],
      [ProductTypeCode.GALLERI]: [ProductTypeCode.GALLERI]
    };
  
    // Check if the productType exists in creditDict and verify credits
    return creditDict[productType]
      ? Array.isArray(creditDict[productType])
        ? creditDict[productType].some(credit => user.credits.includes(credit))
        : user.credits.includes(creditDict[productType])
      : false;
  }

}

export default UserHelper