import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { DexaIntro } from "./dexaIntro.component.js"
import { ConfirmDexa } from "./confirmDexa.component.js"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode} from '../../services/membershipType.service'
import { MembershipPointsModal } from "../membershipPointsModal/membersipPointsModal.component";
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"

export const Step = {
  ACCOUNT: 'account',
  LOCATION: 'location',
  FACILITY: 'facility',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  PHONE: 'phone',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const DexaBodyCompFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, setHasInstalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [membershipType, setMembershipType] = useState()
  const [facilityPrice, setFacilityPrice] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, setHasInstalabMembership, flow, hasPass])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  useEffect(() => {
    if (flow?.facility && flow.facility.menu) {
      const product = flow.facility.menu.find(f => f.productType === getProductType(ProductTypeCode.DEXA_BODYCOMP)?._id);
      if (product && product.price) {
        setFacilityPrice(product.price);
      }
    }
  }, [flow, productTypes]);

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.DEXA_BODYCOMP,
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const instructions = [
    {
      sections: [{
        title: 'Schedule Your Scan',
        description: <>Our concierge team will reach out to schedule your scan at your chosen imaging center.</>
      }, 
    ]
    }, 
    {
      sections: [{
        title: 'Complete the Scan',
        description: <>Wear comfortable clothing. The scan takes about 15 minutes, and results are usually available immediately after your appointment.</>
      }]
    },
    {
      sections: [{
        title: 'Review Your Results',
        description: <>Instalab physicians will review your results with you and provide personalized recommendations.</>
      }]
    }]

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <DexaIntro  footer={footer} instructions={instructions}/>
        
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.DEXA_BODYCOMP}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },

      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.FACILITY,
        title: `What's your current address?`,
        description: `We'll search for imaging centers near this address.`,
        field: {
          name: 'location'
        },
      },
      [Step.FACILITY]: {
        type: StepType.FACILITY,
        nextStep: Step.NAME,
        buildFlow: true,
        productTypeCode: ProductTypeCode.DEXA_BODYCOMP,
        showPrice: true,
        title: `Select your preferred imaging center.`,
        description: `We’ll book your DEXA scan at this facility. You’ll pay the facility directly at your appointment.`,
        field: {
          name: 'facility',
          required: true,
        },
      },

      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.DOB
          }
        },
        buildUser: true,
        title: `What's your biological sex?`,
        description: "This is required for the physician order.",
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `Are you currently pregnant?`,
        description: 'Please note you should not get the scan done if there is any chance you may be pregnant.',
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender === Gender.MALE
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },

      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },

      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: !flow?.addLongevityMembership,
        productType: ProductTypeCode.DEXA_BODYCOMP,
        addLongevityMembership: true,
        title: (
          <>
            {(flow?.addLongevityMembership || instalabMembership) ? (
              <>
                Pay <span className="true-price">${getProductType(ProductTypeCode.DEXA_BODYCOMP)?.memberCost}</span> for the service fee and <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership.
              </>
            ) : (
              <>Pay <span className="true-price">${getProductType(ProductTypeCode.DEXA_BODYCOMP)?.cost}</span> for the service fee.</>
            )}
          </>
        ),
        description: <>This fee covers your scheduling, reviewing your results, and personalized report with recommendations. <i>Note: This doesn't include the scan cost{facilityPrice && ` ($${facilityPrice})`}, which you'll pay directly to the facility.</i></>,
        membershipCTA: '$0 Service Fees with Instalab Membership',
        skipIf: () => {
          return instalabMembership 
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `All set! We'll email you within 1 business day.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmDexa instructions={instructions}/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },


    })
  }

  return <>
    {skeleton && (
      <>
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />

      <MembershipPointsModal
        open={openMembershipPointsModal}
        setOpen={setOpenMembershipPointsModal}
      />
      </>
    )}
    
  </>
}