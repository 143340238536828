const ReferralType = Object.freeze({
    ALLERGIST: 'allergist',
    CARDIOLOGIST: 'cardiologist',
    DENTAL: 'dentist',
    DERMATOLOGIST: 'dermatologist',
    ENDOCRINOLOGIST: 'endocrinologist',
    GASTROENTEROLOGIST: 'gastroenterologist',
    LIPIDOLOGIST: 'lipidologist',
    NEPHROLOGIST: 'nephrologist',
    NEUROLOGY: 'neurologist',
    NUTRIONIST: 'nutrionist',
    STRENGTH_COACH: 'strength coach',
    THERAPIST: 'therapist',
    ONCOLOGIST: 'oncologist',
    OPTHALMOLOGIST: 'opthalmologist',
    PSYCHIATRIST: 'psychiatrist',
    UROLOGIST: 'urologist',
})

export default ReferralType