import React, { useContext, useEffect, useState } from "react";
import { Button, Switch, message, Modal } from "antd";
import { CheckCircleOutlined, EyeOutlined, StopOutlined, EditOutlined, ExperimentOutlined, UploadOutlined } from "@ant-design/icons";
import { FlexibleTable } from "../flexibleTable/flexibleTable.component";
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import moment from "moment";
import { useNavigate } from 'react-router-dom';
import { listResults, updateResult, removeResult, uploadCsvFile } from "../../services/result.service";
import UrlHelper from '../../helpers/url.helper.js';
import { PageHeader } from "../pageHeader/pageHeader.component";
import { TestResultsModal } from "../testResultsModal/testResultsModal.component";
import ResultStatus from "../../enums/resultStatus.enum.js";
import Permission from "../../enums/permission.enum.js";
import Role from "../../enums/role.enum.js";
import { UserContext } from "../../contexts/user.context.js";
import { IncludedTestsModal } from "../includedTestsModal/includedTestsModal.component.js";
import ResultHelper from "../../helpers/result.helper.js";
import { IncompleteTestTooltip } from "../incompleteTestTooltip/incompleteTestTooltip.component.js";
import Color from "../../colors.scss";
import "./adminResults.scss";

const select = '_id status patient order hasRedraw fasting collectedAt values tests'
const populate = [{
  path: 'patient',
  select: '_id firstName lastName'
}, {
  path: 'order',
  select: 'panel'
}, {
  path: 'tests',
  select: '_id name isCalculated'
}]

export const AdminResults = () => {
  const [results, setResults] = useState([]);
  const [resultId, setResultId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openIncludedTests, setOpenIncludedTests] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const navigate = useNavigate()
  const [isTestResultsModalOpen, setIsTestResultsModalOpen] = useState();
  const [filteredCount, setFilteredCount] = useState();
  const [panels, setPanels] = useState([]);
  const booleanOptions = [{
    label: 'Yes',
    value: true
  }, {
    label: 'No',
    value: false
  }]

  useEffect(() => {
    document.title = '[Admin] Test Results'
    fetchResults();
  }, []);

  const fetchResults = async () => {
    setIsLoading(true)
    let params = {
      select,
      filter: {
        isRemoved: {
          $ne: true
        }
      },
      populate,
      sort: '-collectedAt'
    }

    const fetchedResults = await listResults(params)
    setPanels([...new Set(fetchedResults.filter(({ order }) => order?.panel).map(({ order }) => order.panel))].sort())
    setResults(fetchedResults)
    setFilteredCount(fetchedResults.length)
    setIsLoading(false)
  };

  const onIncludedTestsSuccess = (result) => {
    setResults(cachedResults => {
      return cachedResults.map(r => r._id === result._id ? result : r)
    })
  }

  const getCustomFilter = (result, value) => {
    return {
      patient: () => result.patient && `${result.patient.firstName} ${result.patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      order: () => result.order?.panel === value,
    }
  }

  const confirmDelete = (resultId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this result?",
      content: "This action cannot be undone.",
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "No, Cancel",
      onOk: async () => {
        try {
          await removeResult(resultId);
          setResults(results => results.filter(r => r._id !== resultId));
          setFilteredCount(filteredCount - 1);
          message.success("Result deleted successfully");
        } catch (error) {
          message.error("Failed to delete result");
        }
      },
    });
  };

  const handleFileUpload = (resultId) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
  
    input.onchange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          const formData = new FormData();
          formData.append('file', file);
          const response = await uploadCsvFile(resultId, formData); // API call to upload the file
          console.log(response)
          if (response.success) {
            message.success('File uploaded successfully');
            await fetchResults(); // Refresh the results
          } else {
            message.error('Failed to upload file');
          }
        } catch (error) {
          message.error('Error uploading file');
        }
      }
    };
  
    input.click();
  };




  const getActionItems = (result) => {
    const isAdmin = currentUser?.role === Role.ADMIN

    return [{
      key: 'view',
      label: (
        <Button onClick={() => {
          navigate(UrlHelper.getPatientProfile(result.patient?._id, 'Results'))
        }}>
          <EyeOutlined /> View
        </Button>
      )
    }, 
    
    isAdmin && {
      key: 'upload',
      label: (
        <Button
          icon={<UploadOutlined />}
          onClick={() => handleFileUpload(result._id)} // Handle file upload
        >
          Upload CSV
        </Button>
      )
    },
    
    isAdmin && {
      key: 'edit',
      label: (
        <Button
          onClick={() => {
            setResultId(result._id);
            setIsTestResultsModalOpen(true);
          }}
        >
          <EditOutlined /> Edit
        </Button>
      )
    }, isAdmin && {
      key: 'tests',
      label: (
        <Button
          onClick={() => {
            setResultId(result._id);
            setOpenIncludedTests(true);
          }}
        >
          <ExperimentOutlined /> Included Tests
        </Button>
      )
    }, 
    // isAdmin && {
    //   key: 'report',
    //   label: (
    //     <Button
    //       onClick={async () => {
    //         try {
    //           // Call a service to create or run the associated report
    //           const report = await updateReport(result._id);
    //           message.success('Report created/updated successfully');
    //           // Optionally update the UI to reflect the new report status
    //         } catch (error) {
    //           message.error('Failed to create or run the report');
    //         }
    //       }}
    //     >
    //       <UploadOutlined /> Create/Run Report
    //     </Button>
    //   )
    // },
    isAdmin && {
      type: 'divider'
    },
      isAdmin && {
      key: 'delete',
      label: (
        <Button
          danger
          onClick={() => confirmDelete(result._id)}
        >
          <StopOutlined /> Delete
        </Button>
      )
    }]
  }

  return (
    <div className="admin-results">
      <PageHeader
        title="Test Results"
        count={filteredCount}
        actions={currentUser?.role === Role.ADMIN && (
          <Button
            type="primary"
            onClick={() => {
              setResultId(null);
              setIsTestResultsModalOpen(true);
            }}
          >
            + Add Result
          </Button>
        )}
      />

      <IncludedTestsModal
        resultId={resultId}
        open={openIncludedTests}
        setOpen={setOpenIncludedTests}
        onSuccess={onIncludedTestsSuccess}
        select={select}
        populate={populate}
      />

      <TestResultsModal
        resultId={resultId}
        setResultId={setResultId}
        open={isTestResultsModalOpen}
        setOpen={setIsTestResultsModalOpen}
        select={select}
        populate={populate}
        onSuccess={(result) => {
          if (results.some(r => r._id === result._id)) {
            setResults(results.map((p) => (p._id === resultId ? result : p)));
          } else {
            setResults([result, ...results]);
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                results: cachedCounts.results + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <FlexibleTable
        pageSize={30}
        isLoading={isLoading}
        records={results}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[currentUser?.role === Role.ADMIN && {
          title: "Approved",
          dataIndex: "status",
          render: (status, { _id }) => {
            return (
              <Switch
                defaultChecked={status === ResultStatus.APPROVED}
                onChange={async (checked) => {
                  const result = await updateResult(_id, {
                    fields: {
                      status: checked ? ResultStatus.APPROVED : ResultStatus.PENDING,
                      approvedAt: checked ? new Date() : null
                    },
                    select,
                    populate
                  });
                  setResults(results.map((r) => r._id === result._id ? result : r))
                  message.info(
                    result.status === ResultStatus.PENDING
                      ? "Approval removed"
                      : "Result approved"
                  );
                }}
              />
            )
          },
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(ResultStatus)
        }, {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            if (patient) {
              return (
                <a 
                  onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Results'))}
                >
                  {patient.firstName} {patient.lastName}
                </a>
              )
            }
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Panel',
          dataIndex: 'order',
          render: (order) => order?.panel ? <>{order?.panel}</> : <>Custom</>,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: panels,
        }, 
        
        currentUser?.role === Role.ADMIN && {
          title: "Redraw",
          dataIndex: "hasRedraw",
          render: hasRedraw => {
            return hasRedraw ? (
              <CheckCircleOutlined style={{ color: Color.success }} />
            ) : (
              <StopOutlined style={{ color: Color.error }} />
            )
          },
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: booleanOptions
        }, 
        
        (currentUser?.role === Role.ADMIN || currentUser?.permissions?.includes(Permission.RESULT_PROGRESS)) && {
          title: '% Complete',
          dataIndex: 'tests',
          render: (tests, result) => {
            if (!tests?.length) return
            const { percentageComplete } = ResultHelper.getProgressData(result)
            return (
              <IncompleteTestTooltip 
                result={result} 
                label={`${percentageComplete}%`}
              />
            )
          }
        }, 

        (currentUser?.role === Role.ADMIN) && {
          title: '# Tests',
          dataIndex: 'result',
          render: (_, result) => result?.values?.length
        }, 
        // {
        //   title: "Fasting",
        //   dataIndex: "fasting",
        //   render: (fasting) => {
        //     return fasting ? (
        //       <CheckCircleOutlined style={{ color: Color.success }} />
        //     ) : (
        //       <StopOutlined style={{ color: Color.error }} />
        //     )
        //   },
        //   filterDropdownType: FilterDropdownType.CHECKBOX,
        //   filterOptions: booleanOptions
        // }, 
        {
          title: "Collected",
          dataIndex: "collectedAt",
          render: (collectedAt) => moment(collectedAt).format("MMM D, YYYY h:mm a")
        },

          ]}
      />
    </div>
  );
};
