import FlowType from "../enums/flowType.enum"
import { GalleriFlow, Step as GalleriStep  } from "../components/galleriFlow/galleriFlow.component"
import { MemberFlow, Step as MemberStep  } from "../components/memberFlow/memberFlow.component"
import { PrescriptionFlow, Step as PrescriptionStep  } from "../components/prescriptionFlow/prescriptionFlow.component"
import { LongevityConsultFlow, Step as LongevityConsultStep  } from "../components/longevityConsultFlow/longevityConsultFlow.component"
import { IntakeFlow, Step as IntakeStep  } from "../components/intakeFlow/intakeFlow.component"
import { PrenuvoFlow, Step as PrenuvoStep  } from "../components/prenuvoFlow/prenuvoFlow.component"
import { TruageCompleteFlow, Step as TruageCompleteStep  } from "../components/truageCompleteFlow/truageCompleteFlow.component"
import { ComprehensiveTestFlow, Step as ComprehensiveTestStep  } from "../components/comprehensiveTestFlow/comprehensiveTestFlow.component"
import { CacFlow, Step as CacStep  } from "../components/cacFlow/cacFlow.component"
import { QuizFlow, Step as QuizStep  } from "../components/quizFlow/quizFlow.component"
import { TruagePaceFlow, Step as TruagePaceStep  } from "../components/truagePaceFlow/truagePaceFlow.component"
import { HeartHealthTestFlow, Step as HeartHealthTestStep  } from "../components/heartHealthTestFlow/heartHealthTestFlow.component"
import { SupplementFlow, Step as SupplementStep  } from "../components/supplementFlow.js/supplementFlow.component"
import { LongevityTestFlow, Step as LongevityTestStep  } from "../components/longevityTestFlow/longevityTestFlow.component"
import { UpgradeFlow, Step as UpgradeStep  } from "../components/upgradeFlow/upgradeFlow.component"
import { CholesterolTreatmentTestFlow, Step as CholesterolTreatmentTestStep  } from "../components/cholesterolTreatmentTestFlow/cholesterolTreatmentTestFlow.component"
import { InviteFlow, Step as InviteStep  } from "../components/inviteFlow/inviteFlow.component"
import { ToxicMetalsTestFlow, Step as ToxicMetalsTestStep  } from "../components/toxicMetalsTestFlow/toxicMetalsTestFlow.component"
import { FertilityTestFlow, Step as FertilityTestStep } from "../components/fertilityTestFlow/fertilityTestFlow.component"
import { AthleteTestFlow, Step as AthleteTestStep } from "../components/athleteTestFlow/athleteTestFlow.component"
import { DexaFlow, Step as DexaStep } from "../components/dexaFlow/dexaFlow.component"
import { ToxicMoldsTestFlow, Step as ToxicMoldsTestStep } from "../components/toxicMoldsTestFlow/toxicMoldsTestFlow.component"
import { ProConsultFlow, Step as ProConsultStep } from "../components/proConsultFlow/proConsultFlow.component"
import { SleepApneaTestFlow, Step as SleepApneaTestStep } from "../components/sleepApneaTestFlow/sleepApneaTestFlow.component"
import { ProSignupFlow, Step as ProSignupStep } from "../components/proSignupFlow/proSignupFlow.component"
import { BiobeatFlow, Step as BiobeatStep } from "../components/biobeatFlow/biobeatFlow.component"
import { ProDrawFlow, Step as ProDrawStep } from "../components/proDrawFlow/proDrawFlow.component"
import { CleerlyFlow, Step as CleerlyStep } from "../components/cleerlyFlow/cleerlyFlow.component"
import { TotalTestosteroneTestFlow, Step as TotalTestosteroneTestStep  } from "../components/totalTestosteroneTestFlow/totalTestosteroneTestFlow.component"
import { TestosteronePanelFlow, Step as TestosteronePanelTestStep  } from "../components/testosteronePanelFlow/testosteronePanelFlow.component"
import { CgmFlow, Step as CGMStep } from "../components/cgmFlow/cgmFlow.component"
import { HeartHealthIntakeFlow, Step as HeartHealthIntakeStep } from "../components/heartHealthIntake/heartHealthIntake.component"
import { UltimateMemberFlow, Step as UltimateMemberStep } from "../components/ultimateMemberFlow/ultimateMemberFlow.component"
import { LeqvioFlow, Step as LeqvioStep } from "../components/leqvioFlow/leqvioFlow.component"
import { DexaBodyCompFlow, Step as DexaBodyCompStep } from "../components/dexaBodyCompFlow/dexaBodyCompFlow.component"
import { HeartHealthUpgradeFlow, Step as HeartHealthUpgradeStep } from "../components/heartHealthUpgrade/heartHealthUpgrade.component"
import { HeartMemberConsultFlow, Step as HeartMemberConsultStep } from "../components/heartMemberConsultFlow/heartMemberConsultFlow.component"
import { LongevityGeneticTestFlow, Step as LongevityGeneticTestStep } from "../components/longevityGeneticTestFlow/longevityGeneticTestFlow.component"
import { Vo2maxFlow, Step as Vo2maxStep } from "../components/vo2maxFlow/vo2maxFlow.component"
import { DiurnalCortisolProfileFlow, Step as DiurnalCortisolProfileStep } from "../components/diurnalCortisolProfileFlow/diurnalCortisolProfileFlow.component"
import { LongevityTestPscFlow, Step as LongevityTestPscStep } from "../components/longevityTestPscFlow/longevityTestPscFlow.component"
import { GiftFlow, Step as GiftStep} from "../components/giftFlow/giftFlow.component"
import { HeartHealthGeneticTestFlow, Step as HeartHealthGeneticTestStep } from "../components/heartHealthGeneticTestFlow/heartHealthGeneticTestFlow.component"
import { DemoFlow, Step as DemoStep } from "../components/demoFlow/demoFlow.component"
import { ReferralFlow, Step as ReferralStep } from "../components/referralFlow/referralFlow.component"

const FlowHelper = {
  getFlow: flowType => {
    switch (flowType) {
      case FlowType.INVITE:
        return {
          component: <InviteFlow />,
          step: InviteStep,
          forceNew: false
        }
      case FlowType.PRO_SIGNUP:
        return {
          component: <ProSignupFlow />,
          step: ProSignupStep,
          forceNew: false
        }
      case FlowType.HEART_MEMBER_CONSULT:
        return {
          component: <HeartMemberConsultFlow />,
          step: HeartMemberConsultStep,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_UPGRADE:
        return {
          component: <HeartHealthUpgradeFlow />,
          step: HeartHealthUpgradeStep,
          forceNew: false
        }
      case FlowType.HEART_HEALTH_INTAKE:
        return {
          component: <HeartHealthIntakeFlow />,
          step: HeartHealthIntakeStep,
          forceNew: true
        }
      case FlowType.PRO_DRAW:
        return {
          component: <ProDrawFlow />,
          step: ProDrawStep,
          forceNew: true
        }
      case FlowType.LONGEVITY_TEST:
        return {
          component: <LongevityTestFlow />,
          step: LongevityTestStep,
          forceNew: true
        }
      case FlowType.LONGEVITY_TEST_PSC:
        return {
          component: <LongevityTestPscFlow/>,
          step: LongevityTestPscStep,
          forceNew: true
        }
      case FlowType.ATHLETE_TEST:
        return {
          component: <AthleteTestFlow />,
          step: AthleteTestStep,
          forceNew: true
        }
      case FlowType.COMPREHENSIVE_TEST:
        return {
          component: <ComprehensiveTestFlow/>,
          step: ComprehensiveTestStep,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_TEST:
        return {
          component: <HeartHealthTestFlow />,
          step: HeartHealthTestStep,
          forceNew: true,
        }
      case FlowType.CHOLESTEROL:
        return {
          component: <PrescriptionFlow/>,
          step: PrescriptionStep,
          forceNew: true,
        }
      case FlowType.GALLERI_TEST:
        return {
          component: <GalleriFlow/>,
          step: GalleriStep,
          forceNew: true,
        }
      case FlowType.LONGEVITY_CONSULT:
        return {
          component: <LongevityConsultFlow/>,
          step: LongevityConsultStep,
          forceNew: true
        }
      case FlowType.PRO_CONSULT:
        return {
          component: <ProConsultFlow />,
          step: ProConsultStep,
          forceNew: true
        }
      case FlowType.MEMBER:
        return {
          component: <MemberFlow/>,
          step: MemberStep,
          forceNew: true,
        }
      case FlowType.INTAKE:
        return {
          component: <IntakeFlow/>,
          step: IntakeStep,
          forceNew: true,
        }
      case FlowType.PRENUVO:
        return {
          component: <PrenuvoFlow/>,
          step: PrenuvoStep,
          forceNew: true
        }
      case FlowType.TRUAGE_COMPLETE:
        return {
          component: <TruageCompleteFlow />,
          step: TruageCompleteStep,
          forceNew: true
        }
      case FlowType.TRUAGE_PACE:
        return {
          component: <TruagePaceFlow />,
          step: TruagePaceStep,
          forceNew: true
        }
      case FlowType.DIURNAL_CORTISOL_PROFILE:
        return {
          component: <DiurnalCortisolProfileFlow/>,
          step: DiurnalCortisolProfileStep,
          forceNew: true
        }
      case FlowType.TOXIC_METALS_TEST:
          return {
            component: <ToxicMetalsTestFlow/>,
            step: ToxicMetalsTestStep,
            forceNew: true
          }
      case FlowType.CAC:
        return {
          component: <CacFlow/>,
          step: CacStep,
          forceNew: true,
        }
      case FlowType.CLEERLY:
        return {
          component: <CleerlyFlow/>,
          step: CleerlyStep,
          forceNew: true,
        }
      case FlowType.QUIZ:
        return {
          component: <QuizFlow/>,
          step: QuizStep,
          forceNew: true,
        }
      case FlowType.SUPPLEMENTS:
        return {
          component: <SupplementFlow/>,
          step: SupplementStep,
          forceNew: true
        }
      case FlowType.UPGRADE:
        return {
          component: <UpgradeFlow />,
          step: UpgradeStep,
          forceNew: true,
        }
      case FlowType.CHOLESTEROL_TREAMTENT_TEST:
        return {
          component: <CholesterolTreatmentTestFlow/>,
          step: CholesterolTreatmentTestStep,
          forceNew: true
        }
      case FlowType.DEXA:
        return {
          component: <DexaFlow/>,
          step: DexaStep,
          forceNew: true
        }
      case FlowType.DEXA_BODYCOMP:
        return {
          component: <DexaBodyCompFlow/>,
          step: DexaBodyCompStep,
          forceNew: true
        }
      case FlowType.FERTILITY:
        return {
          component: <FertilityTestFlow/>,
          step: FertilityTestStep,
          forceNew: true
        }
      case FlowType.MOLD_TOXINS:
        return {
          component: <ToxicMoldsTestFlow/>,
          step: ToxicMoldsTestStep, 
          forceNew: true
        }
      case FlowType.SLEEP_APNEA:
        return {
          component: <SleepApneaTestFlow/>,
          step: SleepApneaTestStep, 
          forceNew: true
        }
      case FlowType.BIOBEAT:
        return {
          component: <BiobeatFlow/>,
          step: BiobeatStep, 
          forceNew: true
        }      
      case FlowType.TESTOSTERONE:
        return {
          component : <TotalTestosteroneTestFlow/>,
          step: TotalTestosteroneTestStep,
          forceNew: true
        }
      case FlowType.TESTOSTERONE_PANEL:
        return {
          component: <TestosteronePanelFlow/>,
          step: TestosteronePanelTestStep,
          forceNew: true
        }
      case FlowType.CGM:
        return {
          component: <CgmFlow/>,
          step: CGMStep,
          forceNew: true
        }
      case FlowType.ULTIMATE_MEMBERSHIP:
        return {
          component: <UltimateMemberFlow/>,
          step: UltimateMemberStep,
          forceNew: true,
        }
      
      case FlowType.LEQVIO:
        return {
          component: <LeqvioFlow/>,
          step: LeqvioStep,
          forceNew: true
        }
      
      case FlowType.LONGEVITY_GENETIC_TEST:
        return {
          component: <LongevityGeneticTestFlow/>,
          step: LongevityGeneticTestStep,
          forceNew: true
        }
      case FlowType.VO2MAX:
        return {
          component: <Vo2maxFlow/>,
          step: Vo2maxStep,
          forceNew: true
        }
      case FlowType.GIFT:
        return {
          component: <GiftFlow/>,
          step: GiftStep,
          forceNew: true
        }
      case FlowType.HEART_HEALTH_GENETIC_TEST:
        return {
          component: <HeartHealthGeneticTestFlow/>,
          step: HeartHealthGeneticTestStep,
          forceNew: true
        }
      case FlowType.REFERRAL:
        return {
          component: <ReferralFlow/>,
          step: ReferralStep,
          forceNew: true
        }
      
      case FlowType.DEMO:
        return {
          component: <DemoFlow/>,
          step: DemoStep,
          forceNew: true
        }
      default:
        return null
    }
  }
}

export default FlowHelper