import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service.js"
import ProductHelper from "../../helpers/product.helper.js"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  DELIVERY: 'delivery',
  PAY_SELECT: 'pay-select',
  PAY_CARD: 'pay-card',
  PAY_KLARNA: 'pay-klarna',
  CONFIRM: 'confirm',
}

export const DemoFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipType, setMembershipType] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, hasPass, productTypes, instalabMembership, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.DEMO_PRODUCT,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.DEMO_PRODUCT}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY_SELECT,
        title: `What's your delivery address?`,
        description: `We’ll send your DEMO_PRODUCT kit to this location.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },
      [Step.PAY_SELECT]: {
        type: StepType.PRODUCT_SELECT,
        title: `Choose how to pay for your ${getProductType(ProductTypeCode.DEMO_PRODUCT)?.title}.`,
        description: `This includes an at-home blood draw and results and report within 2 weeks.`,
        buildUser: true,
        multiple: false,
        onNextStep: (patient) => {
          return patient.paymentPreference || Step.PAY_CARD 
        },
        field: {
          name: 'paymentPreference',
          required: true,
          options: [
            {
              title: `Pay $${getProductType(ProductTypeCode.DEMO_PRODUCT)?.cost} now`,
              value: Step.PAY_CARD,
              description: 'All major credit cards accepted.',
              tag: null,
              price: null
            }, 
            {
              title: `Pay over time with Klarna`,
              value: Step.PAY_KLARNA,
              description: `From $${ProductHelper.getMonthlyPayment(getProductType(ProductTypeCode.DEMO_PRODUCT)?.cost, 12, 0.0799).toFixed(0)} per month for 12 months. Interest may apply.`,
              tag: null,
              price: null
            },
          ].filter(Boolean) 
        },
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.DEMO_PRODUCT].includes(product.productType.code)).length > 0
        },
      },

      [Step.PAY_KLARNA]: {
        type: StepType.KLARNA_PAY,
        nextStep: Step.CONFIRM,
        productType: ProductTypeCode.DEMO_PRODUCT,
        title: <>Pay with Klarna. One moment please.</>,
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.DEMO_PRODUCT].includes(product.productType.code)).length > 0
        },
      },
      
      [Step.PAY_CARD]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: true,
        productType: ProductTypeCode.DEMO_PRODUCT,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.DEMO_PRODUCT)?.cost}</span> for the {getProductType(ProductTypeCode.DEMO_PRODUCT)?.title}.</>,
        description: `This includes an at-home blood draw and results and report within 2 weeks.`,
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.DEMO_PRODUCT].includes(product.productType.code)).length > 0
        },
      },

     
     

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You're all set!`,
        description: `DEMO_PRODUCT will send you a kit for your blood draw within 1 business day. Below are next steps after you receive it.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
  
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}