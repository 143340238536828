import {
  CalendarOutlined,
  VideoCameraAddOutlined,
  UserOutlined,
  DollarCircleOutlined,
  TableOutlined,
  FileSearchOutlined,
  ExperimentOutlined,
  ReadOutlined,
  LoginOutlined,
  GiftOutlined,
  LogoutOutlined,
  EditOutlined,
  ShopOutlined,
  ProfileOutlined,
  HomeOutlined,
  MessageOutlined,
  FileDoneOutlined,
  CheckSquareOutlined,  
  BankOutlined,
  SendOutlined,
  HeartOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { ReactComponent as TubeOutlined } from '../assets/svg/blood-vial.svg'
import { ReactComponent as ScienceOutlined } from "../assets/svg/science.svg"
import { ReactComponent as DoctorOutlined } from "../assets/svg/doctor.svg"
import FlowType from '../enums/flowType.enum'
import Role from '../enums/role.enum';
import Permission from '../enums/permission.enum';
import FrontChatHelper from './fronchat.helper';

const LayoutHelper = {

  getMenuItems: (counts, currentUser, membership, navigate, consults) => {
    if (!counts) return

    FrontChatHelper.identifyUser(currentUser);


    const testList = [
      {
        key: 'truage',
        label: '- Biological Age Test',
        urls: ['/truage'],
        onClick: () => navigate(`/truage`)
      },
      // {
      //   key: 'cgm',
      //   label: "- Continuous Glucose Monitor",
      //   urls: [`/flow/${FlowType.CGM}`],
      //   onClick: () => navigate(`/flow/${FlowType.CGM}`)
      // },
      {
        key: 'ccta',
        label: "- CT Coronary Angiogram",
        urls: [`/cleerly`],
        onClick: () => navigate(`/cleerly`)
      },
      {
        key: 'biobeat',
        label: "- Continuous BP Monitor",
        urls: [ `/biobeat`],
        onClick: () => navigate(`/biobeat`)
      },
      {
        key: 'dexa-bodycommp',
        label: "- DEXA Body Composition",
        urls: [`/flow/${FlowType.DEXA_BODYCOMP}`],
        onClick: () => navigate(`/flow/${FlowType.DEXA_BODYCOMP}`)
      },
      {
        key: 'dexa-bone',
        label: "- DEXA Bone Density",
        urls: [`/flow/${FlowType.DEXA}`],
        onClick: () => navigate(`/flow/${FlowType.DEXA}`)
      },
      {
        key: 'cortisol',
        label: "- Diurnal Cortisol Profile",
        urls: [`/flow/${FlowType.DIURNAL_CORTISOL_PROFILE}`],
        onClick: () => navigate(`/flow/${FlowType.DIURNAL_CORTISOL_PROFILE}`)
      },
      {
        key: 'fertility',
        label: "- Fertility Hormone Test",
        urls: [`/fertility`],
        onClick: () => navigate(`/fertility`)
      },
      {
        key: 'heart-health',
        label: "- Heart Health Test",
        urls: [ `/heart-health-test`],
        onClick: () => navigate(`/heart-health-test`)
      },
      {
        key: 'mold-toxins',
        label: "- Mold Toxins Test",
        urls: [ `/flow/${FlowType.MOLD_TOXINS}`],
        onClick: () => navigate(`/flow/${FlowType.MOLD_TOXINS}`)
      },
      {
        key: 'sleep',
        label: "- Sleep Apnea Test",
        urls: [`/flow/${FlowType.SLEEP_APNEA}`],
        onClick: () => navigate(`/flow/${FlowType.SLEEP_APNEA}`)
      },
      {
        key: 'testosterone',
        label: "- Testosterone Panel",
        urls: [ `/testosterone`],
        onClick: () => navigate(`/testosterone`)
      },
      {
        key: '5',
        label: "- Toxic Metals Test",
        urls: [ `/flow/${FlowType.TOXIC_METALS_TEST}`],
        onClick: () => navigate(`/flow/${FlowType.TOXIC_METALS_TEST}`)
      },
      {
        key: 'vo2max',
        label: "- Vo2 Max",
        urls: [ `/flow/${FlowType.VO2MAX}`],
        onClick: () => navigate(`/flow/${FlowType.VO2MAX}`)
      },
      {
        key: 'prenuvo',
        label: "- Whole Body MRI Scan",
        urls: [ `/flow/${FlowType.PRENUVO}`],
        onClick: () => navigate(`/flow/${FlowType.PRENUVO}`)
      },
    ]

    const popularTestList = [
      {
        key: 'longevity',
        label: "- Longevity Blood Panel",
        urls: [ "/longevity-panel"],
        onClick: () => navigate("/longevity-panel")
      },
      {
        key: 'cac',
        label: "- Coronary Calcium Scan",
        urls: [`/cac`],
        onClick: () => navigate(`/cac`)
      },
      {
        key: 'galleri',
        label: "- Galleri Cancer Detection Test",
        urls: [ `/galleri-test`],
        onClick: () => navigate(`/galleri-test`)
      },

    ]

    if (currentUser?.role === Role.ADMIN) {

      const apptItems = [
      {
        key: 'appointments',
        label: (
          <>
            - Appointments
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        urls: ['/admin/appointments'],
        onClick:() => navigate('/admin/appointments')
      },       
      {
        key: 'Lab Orders',
        label: (
          <>
            - Lab Orders
            <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
          </>
        ),
        // icon: <ProfileOutlined />,
        urls: ['/admin/orders'],
        onClick:() => navigate('/admin/orders')
      }, 
 
      {
        key: 'calendar',
        label: (
          <>
            - Master Calendar
            <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
          </>
        ),
        urls: ['/admin/calendar'],
        onClick:() => navigate('/admin/calendar')
      }, 
      {
        key: 'Longevity-Consults',
        label: (
          <>
            - Consultations
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        urls: ['/admin/consults'],
        onClick:() => navigate('/admin/consults')
      }, 
      {
        key: 'Events',
        label: (
          <>
            - Events
            <div className="menu-item-count">{counts.events?.toLocaleString()}</div>
          </>
        ),
        urls: ['/admin/events'],
        onClick:() => navigate('/admin/events')
      }, 
      ]

      const userItems = [
        { 
          key: 'Patients', 
          urls: ['/', '/admin/patients'],
          onClick: () => navigate('/'),
          label: (
          <>
            - Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Members', 
          urls: ['/admin/members'],
          onClick: () => navigate('/admin/members'),
          label: (
          <>
            - Members
            <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'HH Members', 
          urls: ['/admin/hhmembers'],
          onClick: () => navigate('/admin/hhmembers'),
          label: (
          <>
            - Heart Health
            <div className="menu-item-count">{counts.hhMembers?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Phlebotomists', 
          urls: ['/admin/phlebotomists'],
          onClick: () => navigate('/admin/phlebotomists'),
          label: (
          <>
            - Phlebotomists
            <div className="menu-item-count">{counts.phlebotomists?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Providers', 
          urls: ['/admin/providers'],
          onClick: () => navigate('/admin/providers'),
          label: (
          <>
            - Providers
            <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Staff', 
          urls: ['/admin/staffs'],
          onClick: () => navigate('/admin/staffs'),
          label: (
          <>
            - Staff
            <div className="menu-item-count">{counts.staff?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Admins', 
          urls: ['/admin/admins'],
          onClick: () => navigate('/admin/admins'),
          label: (
          <>
            - Admins
            <div className="menu-item-count">{counts.admins?.toLocaleString()}</div>
          </>
          )
        },
        {
          key: 'Facilities',
          label: (
            <>
              - Facilities
              <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
            </>
          ),
          urls: ['/admin/facilities'],
          onClick:() => navigate('/admin/facilities')
        }, 
      ]

      const resultItems = [
        {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
              <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/admin/results'],
          onClick:() => navigate('/admin/results')
        }, {
          key: 'reports',
          label: (
            <>
              - Reports
              <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileSearchOutlined />,
          urls: ['/admin/reports'],
          onClick:() => navigate('/admin/reports')
        }, 
        {
          key: 'Biomarkers',
          label: (
            <>
              - Biomarkers
              <div className="menu-item-count">{counts.tests?.toLocaleString()}</div>
            </>
          ),
          // icon: <ExperimentOutlined />,
          urls: ['/admin/tests'],
          onClick:() => navigate('/admin/tests')
        }, 
        {
          key: 'Files',
          label: (
            <>
              - File Archives
              <div className="menu-item-count">{counts.files?.toLocaleString()}</div>
            </>
          ),
          // icon: <FileOutlined />,
          urls: ['/admin/files'],
          onClick:() => navigate('/admin/files')
        }
      ]

      const productItems = [
        {
          key: 'Product-Orders',
          label: (
            <>
              - Product Orders
              <div className="menu-item-count">{counts.products?.toLocaleString()}</div>
            </>
          ),
          // icon: <ShopOutlined />,
          urls: ['/admin/products'],
          onClick:() => navigate('/admin/products')
        },

        {
          key: 'Prescriptions',
          label: (
            <>
              - Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/admin/prescriptions'],
          onClick:() => navigate('/admin/prescriptions')
        }, 

  
        {
          key: 'Memberships',
          label: (
            <>
              - Memberships
              <div className="menu-item-count">{counts.memberships?.toLocaleString()}</div>
            </>
          ),
          // icon: <KeyOutlined />,
          urls: ['/admin/memberships'],
          onClick:() => navigate('/admin/memberships')
        }, 
        { 
          key: 'Membership-Types', 
          urls: ['/admin/membership-types'],
          onClick: () => navigate('/admin/membership-types'),
          label: (
          <>
            - Membership Pricing
            <div className="menu-item-count">{counts.membershipTypes?.toLocaleString()}</div>
          </>
          )
        },
        { 
          key: 'Product-Types', 
          urls: ['/admin/product-types'],
          onClick: () => navigate('/admin/product-types'),
          label: (
          <>
            - Product Pricing
            <div className="menu-item-count">{counts.productTypes?.toLocaleString()}</div>
          </>
          )
        }
      ]

      return [

        {
        key: 'Users',
        label: 'Users',
        icon: <UserOutlined />,
        children: userItems
      }, 
      {
        key: 'Appointments',
        label: 'Appointments',
        icon: <CalendarOutlined />,
        children: apptItems
      },
      {
        key: 'Results',
        label: 'Results',
        icon:  <ExperimentOutlined />,
        children: resultItems
      },
      {
        key: 'Products',
        label: 'Products',
        icon: <ShopOutlined />,
        children: productItems
      },
      {
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/admin/tasks'],
        onClick:() => navigate('/admin/tasks')
      },
      {
        key: 'Referrals',
        label: (
          <>
            Referrals
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/admin/referrals'],
        onClick:() => navigate('/admin/referrals')
      },
      {
        key: 'Billing',
        label: (
          <>
            Billing
            <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
          </>
        ),
        icon: <DollarCircleOutlined />,
        urls: ['/billing'],
        onClick:() => navigate('/billing')
      }, 
 
      // {
      //   key: 'Logout',
      //   label: 'Logout',
      //   icon: <LogoutOutlined />,
      //   urls: ['/logout'],
      //   onClick:() => navigate('/logout')
      // }
      ]
    } 
    else if (currentUser?.role === Role.PROVIDER) {
      let providerItems = [{
        key: 'Patients',
        label: (
          <>
            Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
        </>
        ),
        icon: <UserOutlined />,
        urls: ['/', '/provider/patients'],
        onClick:() => navigate('/')
      }, 
      {
        key: 'Blood-Draws',
        label: (
          <>
            Blood Draws
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        icon: <TubeOutlined />,
        urls: ['/provider/appointments'],
        onClick:() => navigate('/provider/appointments')
      }, {
        key: 'Longevity-Consults',
        label: (
          <>
            Consultations
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        icon: <VideoCameraAddOutlined />,
        urls: ['/provider/consults'],
        onClick:() => navigate('/provider/consults')
      }, {
        key: 'Test-Results',
        label: (
          <>
            Test Results
            <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
          </>
        ),
        icon: <TableOutlined />,
        urls: ['/provider/results'],
        onClick:() => navigate('/provider/results')
      }, {
        key: 'reports',
        label: (
          <>
            Reports
            <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
          </>
        ),
        icon: <FileSearchOutlined />,
        urls: ['/provider/reports'],
        onClick:() => navigate('/provider/reports')
      },
      {
        key: 'referrals',
        label: (
          <>
            Referrals 
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/provider/referrals'],
        onClick:() => navigate('/provider/referrals')
      }]

      if (currentUser?.chargePersonalCard) {
        providerItems.push({
          key: 'Billing',
          label: (
            <>
              Billing
              <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
            </>
          ),
          icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        })
      }

      if (currentUser?.permissions?.includes(Permission.PRESCRIPTIONS)) {
        providerItems.push({
          key: 'Prescriptions',
          label: (
            <>
              Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          icon: <EditOutlined />,
          urls: ['/provider/prescriptions'],
          onClick:() => navigate('/provider/prescriptions')
        })
      }
    
      providerItems = [
        ...providerItems,
        {
          type: 'divider'
        },
        {
          key: 'Contact',
          label: (
            <>
              Concierge
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => {
            window.FrontChat("show")
          }
        }, 
        {
          key: 'Logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
          urls: ['/logout'],
          onClick:() => navigate('/logout')
        }
      ]

      return providerItems
    } 
    else if (currentUser?.role === Role.PHLEBOTOMIST) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/phlebotomist/tasks'],
        onClick:() => navigate('/phlebotomist/tasks')
      }]
    }
    else if (currentUser?.role === Role.STAFF) {
      return [
        {
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/staff/tasks'],
        onClick:() => navigate('/staff/tasks')
      }, 
      {
        key: 'Appointment-Calendar',
        label: (
          <>
            Upcoming Appts
            <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
          </>
        ),
        icon: <CalendarOutlined />,
        urls: ['/staff/calendar'],
        onClick:() => navigate('/staff/calendar')
      }, 
      {
        key: 'Lab Orders',
        label: (
          <>
            Lab Orders
            <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
          </>
        ),
        icon: <ProfileOutlined />,
        urls: ['/staff/orders'],
        onClick:() => navigate('/staff/orders')
      }, 
      { 
        key: 'Phlebotomists', 
        urls: ['/staff/phlebotomists'],
        onClick: () => navigate('/staff/phlebotomists'),
        icon: <UserOutlined/> ,
        label: (
        <>
          Phlebotomists
          <div className="menu-item-count">{counts.phlebotomists?.toLocaleString()}</div>
        </>
        )
      },
      { 
        key: 'Providers', 
        urls: ['/staff/providers'],
        onClick: () => navigate('/staff/providers'),
        icon: <DoctorOutlined/>,
        label: (
        <>
          Providers
          <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
        </>
        )
      },
      {
        key: 'Facilities',
        icon: <BankOutlined/>,
        label: (
          <>
            Facilities
            <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
          </>
        ),
        urls: ['/staff/facilities'],
        onClick:() => navigate('/staff/facilities')
      }, 
      {
        key: 'Referrals',
        label: (
          <>
            Referrals
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/staff/referrals'],
        onClick:() => navigate('/staff/referrals')
      },
      {
        type: 'divider'
      }, {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]
    }
    else if (currentUser?.role === Role.PATIENT && currentUser?.email) {

      let orderItems = [
        {
          key: 'blood-draws',
          label: "- Test Appointments",
          // icon: <CalendarOutlined/>,
          urls: [ "/patient/appointments"],
          onClick: () => navigate("/patient/appointments")
        },
        {
          key: 'consultations',
          label: '- Physician Consults',
          urls: ["/patient/consults"],
          // icon: <DoctorOutlined/>,
          onClick: () => navigate("/patient/consults"),
        },
        {
          key: 'test-kits',
          label: "- Test Kits",
          urls: [ "/patient/kits"],
          // icon: <TubeOutlined/>,
          onClick: () => navigate("/patient/kits")
        },

        membership && {
          key: 'Treatments',
          label: (
            <>
              - Prescriptions
            </>
          ),
          // icon: <PrescriptionOutlined/>,
          urls: ['/patient/treatments'],
          onClick:() => navigate('/patient/treatments')
        },
      ]

      let accountItems = [
        {
          key: 'Membership',
          label: (
            <>
              - Membership
            </>
          ),
          // icon: <IdcardOutlined />,
          urls: ['/patient/memberships'],
          onClick:() => navigate('/patient/memberships')
        },
        (counts.charges > 0) && {
          key: 'Billing',
          label: (
            <>
              - Billing
            </>
          ),
          // icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        }
      ]
    
      
      let myHealthItems=[];

      if (counts.results?.toLocaleString() > 0) {
          myHealthItems = [...myHealthItems, {
          key: 'Test-Results',
          label: (
            <>
              - Test Results
            </>
          ),
          // icon: <TableOutlined />,
          urls: ['/patient/results'],
          onClick:() => navigate('/patient/results')
        }]
      }

      if (counts?.reports>0) {
        myHealthItems = [...myHealthItems, 
          {
            key: 'Latest-Report',
            label: '- Latest Report',
            // icon: <FileSearchOutlined />,
            urls: ['/patient/report'],
            onClick:() => navigate('/patient/report')
          },
          {
            key: 'Game-Plan',
            label: '- Game Plan',
            // icon: <CheckSquareOutlined />,
            urls: ['/patient/plan'],
            onClick:() => navigate('/patient/plan')
          },
        ]
      }

      myHealthItems.push(
        {
        key: 'Medical-Information',
        label: '- Medical Info',
        // icon: <UserOutlined/>,
        urls: ['/patient/medical'],
        onClick:() => navigate('/patient/medical')
      },
      {
        key: 'Files',
        label: (
          <>
            - File Archives
          </>
        ),
        // icon: <FileOutlined />,
        urls: ['/patient/files'],
        onClick:() => navigate('/patient/files')
      })


      // sidebar layout
      let items = [{
        key: 'Home',
        label: <>Home</>,
        icon: <HomeOutlined/>,
        urls: ['/', "/patient/home"],
        onClick:() => navigate('/patient/home'),
      },
      {
        key: 'health',
        label: 'My Health',
        icon: <HeartOutlined/>,
        children: [...myHealthItems]},
      {
        key: 'orders',
        label: 'Orders',
        icon: <ProfileOutlined />,
        children: [...orderItems]
      },
      {
        key: 'account',
        label: 'Account',
        icon: <SettingOutlined/>,
        children: accountItems
      }]

      items = [...items, 
        {
          type: 'divider'
        },  
        {
          key: 'Contact',
          label: (
            <>
              Contact
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => window.FrontChat("show")
        },         
      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },
      {
        key: 'Newsletter',
        label: 'Newsletter',
        icon: <ReadOutlined />,
        urls: ['/newsletter'],
        onClick:() => window.open('/newsletter', '_blank')
      }, 
      {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]

      return items
    } 
    else {
      return [{ 
        key: 'Home',
        label: 'Home',
        icon: <HomeOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, 

      // {
      //   key: 'Membership',
      //   label: 'Join Instalab Membership',
      //   icon: <IdcardOutlined/>,
      //   onClick: () => navigate(`/flow/${FlowType.MEMBER}`)
      // },  

      {
        key: 'popular-tests',
        label: 'Most Popular Tests',
        icon: <TubeOutlined/>,
        children:popularTestList
      }, 

      {
        key: 'testing',
        label: 'Other Popular Tests',
        icon: <ScienceOutlined/>,
        children:testList
      }, 
      // {
      //   key: 'Treatment',
      //   label: 'Cholesterol Medication',
      //   icon: <PrescriptionOutlined/>,
      //   urls: ['/cholesterol'],
      //   onClick:() => navigate('/cholesterol')
      // },

      // {
      //   key: 'consultations',
      //   label: 'Consultations',
      //   icon: <PhysicianIcon/>,
      //   children: [{
      //     key: FlowType.LONGEVITY_CONSULT,
      //     label: 'Longevity Physician',
      //     urls: [`/${FlowType.LONGEVITY_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
      //   }, {
      //     key: FlowType.HEART_HEALTH_CONSULT,
      //     label: 'Clinical Lipidologist',
      //     urls: [`/${FlowType.HEART_HEALTH_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.HEART_HEALTH_CONSULT}`)
      //   }, {
      //     key: FlowType.METABOLIC_HEALTH_CONSULT,
      //     label: 'Metabolic Specialist',
      //     urls: [`/${FlowType.METABOLIC_HEALTH_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.METABOLIC_HEALTH_CONSULT}`)
      //   }]
      // },

      {
        type: 'divider'
      }, 
      {
        key: 'Login',
        label: 'Login',
        icon: <LoginOutlined />,
        urls: ['/login'],
        onClick:() => navigate('/login')
      }, {
        type: 'divider'
      }, 
      { 
        key: 'Contact',
        label: 'Contact',
        icon: <MessageOutlined />,
        onClick:() => window.FrontChat("show")
      }, 
      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        onClick: () => navigate(`/flow/${FlowType.GIFT}`)
      },

            {
        key: 'Newsletter',
        label: 'Newsletter',
        icon: <ReadOutlined />,
        urls: ['/newsletter'],
        onClick:() => window.open('/newsletter', '_blank')
      }, {
        key: 'Terms',
        label: 'Terms',
        icon: <FileDoneOutlined />,
        urls: ['/terms'],
        onClick:() => navigate('/terms')
      }, {
        key: 'Privacy',
        label: 'Privacy',
        icon: <FileDoneOutlined />,
        urls: ['/privacy'],
        onClick:() => navigate('/privacy')
      }]
    }
  }
}

export default LayoutHelper
