import React, { useState, useEffect } from 'react'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Calendar as ReactBigCalendar, momentLocalizer } from 'react-big-calendar'
import { Typography } from 'antd'
import moment from 'moment-timezone'
import "./appointmentCalendar.scss"



const localizer = momentLocalizer(moment)
const Calendar = withDragAndDrop(ReactBigCalendar)
const { Text } = Typography

const EventType = {
  APPOINTMENT: 'appointment',
}

const OpsTime = {
  COMMUTE: 120,
  DRAW_PER_PATIENT: 15,
  PROCESS_PER_PATIENT: 15,
}

export const AppointmentCalendar = ({ appointments, customEvents=[], calendarProps={} }) => {
  const [events, setEvents] = useState([])

  useEffect(() => {
    document.title = 'Instalab | Appointment Calendar'
  }, [])

  useEffect(() => {
    if (appointments) {
      fetchEvents()
    }
  }, [appointments, customEvents])

  const fetchEvents = () => {
    setEvents([
      ...customEvents,
      ...(appointments?.map(({ _id, start, patients, location, phlebotomist }) => {
        const masterPatient = patients[0]
        const phlebStart = moment(start).subtract(OpsTime.COMMUTE, 'minutes').toDate()
        const drawStart = new Date(start)
        const drawEnd = moment(drawStart).add(patients.length * OpsTime.DRAW_PER_PATIENT, 'minutes').toDate()
        let processTime = patients.length * OpsTime.PROCESS_PER_PATIENT
        if (processTime < 30) processTime = 30
        const phlebEnd = moment(drawEnd).add(processTime, 'minutes').toDate()

        const title = (
          <div>
            <Text 
              className="event-patient-name" 
              ellipsis
            >
              {masterPatient.firstName} {masterPatient.lastName} {patients.length > 1 && `(+${patients.length - 1})`}
            </Text>
            <Text className="event-appointment-location" ellipsis>
              {location?.city}, {location?.state}
            </Text>
            {phlebotomist && (
              <Text className="event-phlebotomist-name">
                {phlebotomist.firstName}
              </Text>
            )}

            <div 
              className="draw-range-container"
              style={{
                height: patients.length * OpsTime.DRAW_PER_PATIENT / 30 * 20,
                top: OpsTime.COMMUTE / 30 * 20
              }}
            >
              <Text className="draw-range" ellipsis>
                {moment(drawStart).format('h:mm a')} - {moment(drawEnd).format('h:mm a')}
              </Text>
            </div>
          </div>
        )

        return {
          _id: `appointment-${_id}`,
          type: EventType.APPOINTMENT,
          start: phlebStart,
          end: phlebEnd,
          title,
        }
      }) || [])
    ])
  }

  return (
    <div className="appointment-calendar">
      <Calendar
        defaultView='week'
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['week']}
        selectable
        showMultiDayTimes
        className="calendar-component"
        {...calendarProps}
        eventPropGetter={event => {
          return {
              className: `event-${event.type}`
          }
        }}
      />
    </div>
  )
}