
import { Layout, Menu, Skeleton } from 'antd';
import "./sidebarLayout.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import LayoutHelper from '../../helpers/layout.helper';
import { UserContext } from '../../contexts/user.context';
import { listConsults } from '../../services/consult.service';

const { Sider } = Layout

const SidebarLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { currentUser, counts, instalabMembership, token } = useContext(UserContext)
  const [items, setItems] = useState()
  const [consults, setConsults] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])

  useEffect(() => {
    fetchItems()
  }, [counts, currentUser, instalabMembership, consults])

  useEffect(() => {
    fetchSelectedKeys()
  }, [items, location])

  useEffect(() => {
    fetchConsults()
  }, [token])
  
  const fetchConsults = async () => {
    if (token) {
      setConsults(await listConsults({
        select: '_id'
      }))
    } else {
      setConsults([])
    }
  }

  const fetchItems = () => {
    setItems(LayoutHelper.getMenuItems(counts, currentUser, instalabMembership, navigate, consults))
  }

  const fetchSelectedKeys = () => {
    if (!items?.length) return []


    const selectedItem = items.find(({ urls }) =>  urls?.includes(location.pathname))
    if (selectedItem) {
      setSelectedKeys([selectedItem.key])
      return
    }

    const selectedSubItem = items.find(({ children }) => {

      // Filter out null or undefined values from children
      const filteredChildren = children?.filter(child => child !== null && child !== undefined);
      return filteredChildren?.some(({ urls }) => urls?.includes(location.pathname))
    })
    if (selectedSubItem) {
      setSelectedKeys([selectedSubItem.children.find(({ urls }) => urls?.includes(location.pathname))?.key])
    }
  }


  return (
    <div className="sidebar-layout">
      <Sider width={200}>
        {items?.length ? (
          <Menu
            mode="inline"
            items={items}
            selectedKeys={selectedKeys}
            defaultOpenKeys={['Users', 'popular-tests', 'health', 'orders']}
          />
        ) : (
          <Skeleton 
            className="sidebar-skeleton"
            active 
            paragraph={{ rows: 15 }} 
          />
        )}
      </Sider>
    </div>
  )
}

export default SidebarLayout